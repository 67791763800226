import axios from "axios";
import miniSearch from "minisearch";

export default (config) => ({
    searchIndex: null,
    indexLoaded: false,
    get hasResults() {
        return this.results.length > 0 && this.searchTerm.length >= 3;
    },
    searchTerm: "",
    results: [],
    fetchIndex() {
        let indexPath = "/js/articles-index.json";
        const manifestElement = document.getElementById("mix-manifest");
        if (manifestElement) {
            try {
                const manifest = JSON.parse(manifestElement.innerHTML);
                if (manifest[indexPath]) {
                    indexPath = manifest[indexPath];
                }
            } catch (err) {}
        }
        axios.get(indexPath).then((resp) => {
            this.searchIndex = miniSearch.loadJS(resp.data, {
                fields: ["title", "description", "content"],
                storeFields: ["title", "description", "url"],
            });
            this.indexLoaded = true;
            this.onSearch();
        });
    },
    onSearch() {
        if (!this.indexLoaded) {
            this.fetchIndex();
            return;
        }
        if (this.indexLoaded && this.searchTerm.length >= 3) {
            this.results = this.searchIndex.search(this.searchTerm, {
                boost: { title: 50, description: 10, content: 1 },
                prefix: (term) => term.length > 3,
                fuzzy: (term) => (term.length > 3 ? 0.2 : null),
                combineWith: "AND",
            });
        } else {
            this.results = [];
        }
    },
    handleArrowUp(e) {
        let listEl = e.target.parentElement;
        if (
            listEl.previousElementSibling &&
            listEl.previousElementSibling.nodeName === "LI"
        ) {
            listEl.previousElementSibling.firstChild.focus();
        }
    },
    handleArrowDown(e) {
        let listEl = e.target.parentElement;
        if (
            listEl.nextElementSibling &&
            listEl.nextElementSibling.nodeName === "LI"
        ) {
            listEl.nextElementSibling.firstChild.focus();
        }
    },
    init() {
        this.$watch("searchTerm", (searchTerm) => {
            this.onSearch();
        });
    },
});
