export default (config) => ({
    issuesVisibility: {},
    issues: [],
    receivedElements: false,
    init() {
        window.Livewire.on("initIssuesVisibility", (e) => {
            this.issues = e.issues;
            this.receivedElements = false;
            this.initIssuesVisibility();
        });
        window.Livewire.on("updateIssuesVisibility", () => {
            this.receivedElements = false;
            this.initIssuesVisibility();
        });
        window.Livewire.on("newIssueCreated", (e) => {
            this.issues.push(e[0].issue_instance);
            this.receivedElements = false;
            this.initIssuesVisibility();
        });
        window.addEventListener("message", this.handleMessage.bind(this), true);
    },

    handleMessage(event) {
        let iframe = document.getElementById("visualIframe");
        let site_domain = new URL(iframe.src).origin;

        if (event.origin !== site_domain) {
            return;
        }
        if (event.data.msg === "updateIssueVisibility") {
            this.issuesVisibility[event.data.issue_id] = event.data.is_visible;
        }
    },

    async initIssuesVisibility() {
        if (!this.issues.length) {
            this.receivedElements = true;
            return;
        }
        setTimeout(() => {
            if (!this.receivedElements) {
                window.Livewire.dispatch("updateState", { data: {
                    state: "error",
                    reason: "could not get issues positions from iframe",
                }});
            }
        }, 4000);
        iframe.contentWindow.postMessage(
            {
                function: "passSelectors",
                selectors: JSON.stringify(this.issues),
            },
            site_domain
        );
        for (const issue of this.issues) {
            if (issue.selector) {
                iframe.contentWindow.postMessage(
                    {
                        function: "getIssueVisibility",
                        selector: issue.selector,
                    },
                    site_domain
                );

                const event = await new Promise((resolve) => {
                    const listener2 = (event) => {
                        if (event.origin === site_domain) {
                            removeEventListener("message", listener2, false);
                            resolve(event);
                        }
                    };

                    window.addEventListener("message", listener2);
                });

                if (event.data.msg === "getIssueVisibility") {
                    this.receivedElements = true;
                    this.issuesVisibility[issue.id] = event.data.is_visible;
                }
            } else {
                this.issuesVisibility[issue.id] = false;
            }
        }
    },

    getIsIssueVisible(issueId) {
        return this.issuesVisibility[issueId];
    },
});
