const getNextSibling = function (elem, selector) {
    let sibling = elem.nextElementSibling;
    if (!selector) return sibling;
    while (sibling) {
        if (sibling.matches(selector)) return sibling;
        sibling = sibling.nextElementSibling;
    }
};

const getPreviousSibling = function (elem, selector) {
    var sibling = elem.previousElementSibling;
    if (!selector) return sibling;
    while (sibling) {
        if (sibling.matches(selector)) return sibling;
        sibling = sibling.previousElementSibling;
    }
};

const getBrowserName = () => {
    const userAgent = navigator.userAgent;
    let browserName = "Unknown";
  
    if (/firefox|fxios/i.test(userAgent)) {
      browserName = "Firefox";
    } else if (/edg/i.test(userAgent)) {
      browserName = "Edge";
    } else if (/chrome|crios/i.test(userAgent) && !/edg/i.test(userAgent)) {
      browserName = "Chrome";
    } else if (/safari/i.test(userAgent) && !/chrome|crios/i.test(userAgent)) {
      browserName = "Safari";
    } else if (/opr\//i.test(userAgent)) {
      browserName = "Opera";
    } else if (/trident/i.test(userAgent)) {
      browserName = "Internet Explorer";
    }
  
    return browserName;
}
  

export { 
    getNextSibling,
    getPreviousSibling,
    getBrowserName
};
