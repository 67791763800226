import { getNextSibling, getPreviousSibling } from "../utils";

export default (config) => ({
    data: config.data,
    emptyOption: config.emptyOption ?? null,
    open: false,
    search: "",
    options: {},
    emptyOptionsMessage: "No results match your search.",
    placeholder: config.placeholder,
    selected: config.selected,
    multiple: config.multiple,
    isLoading: false,
    disabled: config.disabled ?? false,
    limit: config.limit ?? -1,

    init: function () {
        if (this.selected == null) {
            if (this.multiple) this.selected = [];
            else this.selected = "";
        }
        if (!this.data) this.data = {};

        this.resetOptions();

        this.$watch("search", (searchTerm) => {
            if (!this.open || !searchTerm) {
                this.resetOptions();
                return;
            }

            this.options = Object.keys(this.data)
                .filter((key) =>
                    this.data[key]
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                )
                .reduce((options, key) => {
                    options[key] = this.data[key];
                    return options;
                }, {});

            this.currentIndex = -1;
        });
    },
    resetOptions: function () {
        this.options = Object.keys(this.data).reduce((options, key) => {
            options[key] = this.data[key];
            return options;
        }, {});
    },
    closeSelect: function () {
        this.open = false;
        this.search = "";
        this.$refs["combobox"].focus();
    },
    toggleSelect: function () {
        if (!this.disabled) {
            if (this.open) return this.closeSelect();

            this.open = true;
        }
    },
    deselectOption: function (key) {
        if (this.multiple) {
            const index = this.selected.indexOf(key);
            if (index > -1) {
                this.selected.splice(index, 1);
            }
        } else {
            this.selected = "";
        }
    },
    selectOption: function (value) {
        if (!this.disabled) {
            if (this.multiple) {
                if (!this.selected.includes(value)) {
                    this.selected.push(value);
                }
            } else {
                this.selected = value;
                this.closeSelect();
            }
        }
    },
    nextOption: function () {
        const currentlyActive = document.activeElement;

        if (currentlyActive.getAttribute("role") === "combobox") {
            this.open = true;
            return this.$refs["search"].focus();
        }

        if (currentlyActive.nodeName === "INPUT") {
            const nextButton = this.$refs["dropdown"].querySelector("button");
            if (nextButton) {
                return nextButton.focus();
            }
        }

        if (currentlyActive.nodeName === "BUTTON") {
            const nextButton = getNextSibling(currentlyActive, "button");
            if (nextButton) {
                return nextButton.focus();
            }
        }
    },
    previousOption: function () {
        const currentlyActive = document.activeElement;
        if (currentlyActive.nodeName === "BUTTON") {
            const prevButton = getPreviousSibling(currentlyActive, "button");
            if (prevButton) {
                return prevButton.focus();
            } else {
                return this.$refs["search"].focus();
            }
        }
        if (
            currentlyActive.nodeName === "INPUT" ||
            (currentlyActive.getAttribute("role") === "combobox" && this.open)
        ) {
            this.closeSelect();
        }
    },
});
