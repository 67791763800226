import Tribute from "tributejs";

const initMentions = function (container, textarea, list) {
    if ( typeof list === 'object' ) {
        list = Object.values(list);
    }

    const mentions = new Tribute({
        values: list,
        allowSpaces: true,
        menuContainer: container,
        noMatchTemplate: function () {
            return null;
        },
        containerClass:
            "mt-1 py-3 px-6 rounded shadow-xl border border-gray-200 bg-white z-50",
        selectClass: "bg-aaardvark-200",
        lookup: "name",
        menuItemLimit: 10,
        menuItemTemplate: function (item) {
            return `<div class="py-1 px-2 cursor-pointer w-full">${item.string} <span class="text-sm text-gray-700">(${item.original.email})</span></div>`;
        },
        selectTemplate: function (item) {
            return `{@${item.original.name}:${item.original.id}}`;
        },
    });

    // Monkey patch to fix https://github.com/zurb/tribute/issues/215
    mentions.events.click = function (instance, event) {
        let tribute = instance.tribute;
        if (tribute.menu && tribute.menu.contains(event.target)) {
            let li = event.target;
            event.preventDefault();
            event.stopPropagation();
            while (li.nodeName.toLowerCase() !== "li") {
                li = li.parentNode;
                if (!li) {
                    break;
                } else if (li === tribute.menu) {
                    return;
                }
            }
            if (!li) return;
            tribute.selectItemAtIndex(li.getAttribute("data-index"), event);
            tribute.hideMenu();

            // TODO: should fire with externalTrigger and target is outside of menu
        } else if (
            tribute.current.element &&
            !tribute.current.externalTrigger
        ) {
            tribute.current.externalTrigger = false;
            setTimeout(() => tribute.hideMenu());
        }
    };

    mentions.attach(textarea);

    /**
     * Search for a mention from the end of a selection.
     *
     * @param {Element} element
     * @returns array|void
     * [start_location, end_location]
     */
    const searchForMentionFromEnd = function (element) {
        for (let i = element.selectionStart; i > -1; i--) {
            const value = element.value.substring(i, element.selectionStart);
            if (value.match(/{@([^}]+):(\d+)}/i)) {
                return [i, element.selectionStart];
            }
        }
    };

    /**
     * Search for a mention from the beginning of a selection.
     *
     * @param {Element} element
     * @returns array|void
     * [start_location, end_location]
     */
    const searchForMentionFromStart = function (element) {
        for (let i = element.selectionStart; i < element.value.length; i++) {
            const value = element.value.substring(element.selectionStart, i);
            if (value.match(/{@([^}]+):(\d+)}/i)) {
                return [element.selectionStart, i];
            }
        }
    };

    /**
     * Search for a mention from the middle of a selection.
     *
     * @param {Element} element
     * @returns array|void
     * [start_location, end_location]
     */
    const searchForMentionFromMiddle = function (element) {
        let i = element.selectionStart - 1;
        let k = element.selectionStart;
        let start = null;
        let end = null;

        while (i >= 0 && k < element.value.length) {
            if (start === null) {
                // Found end of another token, abort.
                if (element.value.substring(i, i + 1) == "}") {
                    return;
                }

                if (element.value.substring(i, i + 2) == "{@") {
                    start = i;
                } else {
                    i--;
                }
            }

            if (end === null) {
                // Found start of another token, abort.
                if (element.value.substring(k - 2, k) == "{@") {
                    return;
                }

                if (element.value.substring(k - 2, k).match(/^\d}$/)) {
                    end = k;
                } else {
                    k++;
                }
            }

            // Found start and end, return range.
            if (start !== null && end !== null) {
                return [start, end];
            }

            // Found start, but no more to check for end.
            if (start !== null && k >= element.value.length) {
                return;
            }
            // Found end, but no more to check for start.
            if (end !== null && i < 0) {
                return;
            }
        }
    };

    /**
     * Delete an entire mention if we delete one
     * character from it. Handles backspace and delete from
     * start/end or within middle of mention.
     */
    textarea.addEventListener("keydown", function (e) {
        const element = e.target;
        const BACKSPACE_KEY = 8;
        const DELETE_KEY = 46;

        if (e.keyCode == BACKSPACE_KEY) {
            if (
                element.value
                    .substring(
                        element.selectionStart - 2,
                        element.selectionStart
                    )
                    .match(/^\d}$/)
            ) {
                const range = searchForMentionFromEnd(element);
                if (range) {
                    [element.selectionStart, element.selectionEnd] = range;
                }
            } else {
                const range = searchForMentionFromMiddle(element);
                if (range) {
                    [element.selectionStart, element.selectionEnd] = range;
                }
            }
        } else if (e.keyCode == DELETE_KEY) {
            if (
                element.value.substring(
                    element.selectionStart,
                    element.selectionStart + 2
                ) == "{@"
            ) {
                const range = searchForMentionFromStart(element);
                if (range) {
                    [element.selectionStart, element.selectionEnd] = range;
                }
            } else {
                const range = searchForMentionFromMiddle(element);
                if (range) {
                    [element.selectionStart, element.selectionEnd] = range;
                }
            }
        }
    });
};

export default initMentions;
