import escape from "escape-string-regexp";
import Formats from "./formats";

/**
 * TextareaEditor class.
 *
 */

const toggleMarkdown = function (el, formatName, ...args) {
    if (hasFormat(el, formatName)) return unformat(el, formatName);
    return format(el, formatName, args);
};

/**
 * Set or get selection range.
 *
 * @param {Array} [range]
 * @return {Array|TextareaEditor}
 */

function range(el, range) {
    if (range == null) {
        return [el.selectionStart || 0, el.selectionEnd || 0];
    }

    focus(el);
    [el.selectionStart, el.selectionEnd] = range;
    return this;
}

/**
 * Insert given text at the current cursor position.
 *
 * @param {String} text - text to insert
 * @return {TextareaEditor}
 */

function insert(el, text) {
    let inserted = true;
    el.contentEditable = true;
    focus(el);

    try {
        inserted = document.execCommand("insertText", false, text);
    } catch (e) {
        inserted = false;
    }

    el.contentEditable = false;

    if (inserted) return;

    try {
        document.execCommand("ms-beginUndoUnit");
    } catch (e) {}

    const { before, after } = selection(el);
    tel.value = before + text + after;

    try {
        document.execCommand("ms-endUndoUnit");
    } catch (e) {}

    const event = document.createEvent("Event");
    event.initEvent("input", true, true);
    el.dispatchEvent(event);
}

/**
 * Set focus on the TextareaEditor's element.
 *
 * @return {TextareaEditor}
 */

function focus(el) {
    if (document.activeElement !== el) el.focus();
}

/**
 * Get selected text.
 *
 * @return {Object}
 * @private
 */

function selection(el) {
    const [start, end] = range(el);
    const value = normalizeNewlines(el.value);
    return {
        before: value.slice(0, start),
        content: value.slice(start, end),
        after: value.slice(end),
    };
}

/**
 * Get format by name.
 *
 * @param {String|Object} format
 * @return {Object}
 * @private
 */

function getFormat(format) {
    if (typeof format == "object") {
        return normalizeFormat(format);
    }

    if (!Formats.hasOwnProperty(format)) {
        throw new Error(`Invalid format ${format}`);
    }

    return normalizeFormat(Formats[format]);
}

/**
 * Format current selcetion with given `format`.
 *
 * @param {String|Object} name - name of format or an object
 * @return {TextareaEditor}
 */

function format(el, name, ...args) {
    const format = getFormat(name);
    const { prefix, suffix, multiline } = format;
    let { before, content, after } = selection(el);
    let lines = multiline ? content.split("\n") : [content];
    let [start, end] = range(el);

    // format lines
    lines = lines.map((line, i) => {
        const pval = maybeCall(prefix.value, line, i + 1, ...args);
        const sval = maybeCall(suffix.value, line, i + 1, ...args);

        if (!multiline || !content.length) {
            start += pval.length;
            end += pval.length;
        } else {
            end += pval.length + sval.length;
        }

        return pval + line + sval;
    });

    let insertVal = lines.join("\n");

    // newlines before and after block
    if (format.block) {
        let nlb = matchLength(before, /\n+$/);
        let nla = matchLength(after, /^\n+/);

        if (before) {
            while (nlb < 2) {
                insertVal = `\n${insertVal}`;
                start++;
                end++;
                nlb++;
            }
        }

        if (after) {
            while (nla < 2) {
                insertVal = `${insertVal}\n`;
                nla++;
            }
        }
    }

    insert(el, insertVal);
    range(el, [start, end]);
}

/**
 * Remove given `format` from current selection.
 *
 * @param {String|Object} name - name of format or an object
 * @return {TextareaEditor}
 */

function unformat(el, name) {
    if (!hasFormat(el, name)) return;

    const format = getFormat(name);

    const { prefix, suffix, multiline } = format;
    const { before, content, after } = selection(el);

    let lines = multiline ? content.split("\n") : [content];

    let [start, end] = range(el);

    // If this is not a multiline format, include prefixes and suffixes just
    // outside the selection.
    if (
        (!multiline || lines.length == 1) &&
        hasSuffix(before, prefix) &&
        hasPrefix(after, suffix)
    ) {
        start -= suffixLength(before, prefix);
        end += prefixLength(after, suffix);
        range(el, [start, end]);
        lines = [selection(el).content];
    }

    // remove formatting from lines
    lines = lines.map((line) => {
        const plen = prefixLength(line, prefix);
        const slen = suffixLength(line, suffix);
        return line.slice(plen, line.length - slen);
    });

    // insert and set selection
    let insertVal = lines.join("\n");
    insert(el, insertVal);
    range(el, [start, start + insertVal.length]);
}

/**
 * Check if current seletion has given format.
 *
 * @param {String|Object} name - name of format or an object
 * @return {Boolean}
 */

function hasFormat(el, name) {
    const format = getFormat(name);
    const { prefix, suffix, multiline } = format;
    const { before, content, after } = selection(el);
    const lines = content.split("\n");

    // prefix and suffix outside selection
    if (!multiline || lines.length == 1) {
        return (
            (hasSuffix(before, prefix) && hasPrefix(after, suffix)) ||
            (hasPrefix(content, prefix) && hasSuffix(content, suffix))
        );
    }

    // check which line(s) are formatted
    const formatted = lines.filter((line) => {
        return hasPrefix(line, prefix) && hasSuffix(line, suffix);
    });

    return formatted.length === lines.length;
}

/**
 * Check if given prefix is present.
 * @private
 */

function hasPrefix(text, prefix) {
    let exp = new RegExp(`^${prefix.pattern}`);
    let result = exp.test(text);

    if (prefix.antipattern) {
        let exp = new RegExp(`^${prefix.antipattern}`);
        result = result && !exp.test(text);
    }

    return result;
}

/**
 * Check if given suffix is present.
 * @private
 */

function hasSuffix(text, suffix) {
    let exp = new RegExp(`${suffix.pattern}$`);
    let result = exp.test(text);

    if (suffix.antipattern) {
        let exp = new RegExp(`${suffix.antipattern}$`);
        result = result && !exp.test(text);
    }

    return result;
}

/**
 * Get length of match.
 * @private
 */

function matchLength(text, exp) {
    const match = text.match(exp);
    return match ? match[0].length : 0;
}

/**
 * Get prefix length.
 * @private
 */

function prefixLength(text, prefix) {
    const exp = new RegExp(`^${prefix.pattern}`);
    return matchLength(text, exp);
}

/**
 * Get suffix length.
 * @private
 */

function suffixLength(text, suffix) {
    let exp = new RegExp(`${suffix.pattern}$`);
    return matchLength(text, exp);
}

/**
 * Normalize newlines.
 * @private
 */

function normalizeNewlines(str) {
    return str.replace("\r\n", "\n");
}

/**
 * Normalize format.
 * @private
 */

function normalizeFormat(format) {
    const clone = Object.assign({}, format);
    clone.prefix = normalizePrefixSuffix(format.prefix);
    clone.suffix = normalizePrefixSuffix(format.suffix);
    return clone;
}

/**
 * Normalize prefixes and suffixes.
 * @private
 */

function normalizePrefixSuffix(value = "") {
    if (typeof value == "object") return value;
    return {
        value: value,
        pattern: escape(value),
    };
}

/**
 * Call if function.
 * @private
 */

function maybeCall(value, ...args) {
    return typeof value == "function" ? value(...args) : value;
}

export default toggleMarkdown;
