export default (config) => ({
    activePanel: '',
    panels: config.panels,
    setActivePanel(panel) {
        if(this.activePanel === panel) {
            this.activePanel = '';
            return;
        }
        this.activePanel = panel;
    },
    focusPreviousPanel(event) {
        if (event.key === 'ArrowUp') {
            const currentIndex = this.panels.indexOf(this.activePanel);
            const previousPanel = this.panels[currentIndex === 0 ? this.panels.length - 1 : currentIndex - 1];
            this.activePanel = previousPanel;
            console.log(`#header-${previousPanel}`)
            document.getElementById(`header-${previousPanel}`).focus();
            event.stopPropagation();
            event.preventDefault();
        }
    },
    focusNextPanel(event) {
        if (event.key === 'ArrowDown') {
            const currentIndex = this.panels.indexOf(this.activePanel);
            const nextPanel = this.panels[currentIndex === this.panels.length - 1 ? 0 : currentIndex + 1];
            this.activePanel = nextPanel;
            document.getElementById(`header-${nextPanel}`).focus();
            event.stopPropagation();
            event.preventDefault();
        }
    },
    focusFirstPanel(event) {
        if (event.key === 'Home') {
            this.activePanel = this.panels[0];
            document.getElementById(`header-${this.activePanel}`).focus();
            event.stopPropagation();
            event.preventDefault();
        }
    },
    focusLastPanel(event) {
        if (event.key === 'End') {
            this.activePanel = this.panels[this.panels.length - 1];
            document.getElementById(`header-${this.activePanel}`).focus();
            event.stopPropagation();
            event.preventDefault();
        }
    },
});
