export default (config) => ({
    activeTab: config.activeTab,
    tabs: config.tabs,
    init() {
        const mainTabs = ['pending_instances', 'ignored_instances', 'fixed_instances', 'comments', 'active_instances'];
        const splitLocationHash = window.location.hash.slice(1).split("/");
        const TabFromHash = splitLocationHash[0];

        this.activeTab =
            this.tabs.indexOf(TabFromHash) > -1 ? TabFromHash : this.tabs[0];

        // TODO: This is a hack to make sure the correct tab is active when the page is loaded, needs to be refactored
        if(mainTabs.includes(this.activeTab)) {
            if(TabFromHash != '') {
                if(TabFromHash != 'comments') {
                    const hasParts = splitLocationHash[0].split('_')
                    let tabName = hasParts[0] + '_' + hasParts[1] + 's'
                    this.activeTab = tabName;
                    this.$wire.emit('activeTabChanged', tabName);
                } else {
                    this.$wire.emit('activeTabChanged', this.activeTab);
                }
            } else {
                this.$wire.emit('activeTabChanged', this.activeTab);
            }

        }

        if (splitLocationHash[1]) {
            const scrollTo = document.getElementById(
                window.location.hash.substr(1)
            );
            if (scrollTo) {
                // wait 300 ms for the tab to be active before scrolling
                setTimeout(() => {
                    scrollTo.scrollIntoView({ behavior: "smooth", block: "center" });
                }
                , 300);
            }
        }
        this.$watch("activeTab", (value) => {
            if(!mainTabs.includes(value)) {
                return;
            } else {
                window.location.hash = ''
                if (config.useHashChange) {
                    window.location.hash = value
                }
                if (this.$wire) {
                    this.$wire.emit('activeTabChanged', value);
                }
            }
        })
    },
    setActiveTab(tab) {
        this.activeTab = tab;
    },
    focusPreviousTab(event) {
        if (event.key === 'ArrowLeft') {
            const currentIndex = this.tabs.indexOf(this.activeTab);
            const previousTab = this.tabs[currentIndex === 0 ? this.tabs.length - 1 : currentIndex - 1];
            this.activeTab = previousTab;
            document.querySelector(`#tab-${previousTab}`).focus();
            event.stopPropagation();
            event.preventDefault();
        }
    },
    focusNextTab(event) {
        if (event.key === 'ArrowRight') {
            const currentIndex = this.tabs.indexOf(this.activeTab);
            const nextTab = this.tabs[currentIndex === this.tabs.length - 1 ? 0 : currentIndex + 1];
            this.activeTab = nextTab;
            document.querySelector(`#tab-${nextTab}`).focus();
            event.stopPropagation();
            event.preventDefault();
        }
    },
    focusFirstTab(event) {
        if (event.key === 'Home') {
            this.activeTab = this.tabs[0];
            document.querySelector(`#tab-${this.activeTab}`).focus();
            event.stopPropagation();
            event.preventDefault();
        }
    },
    focusLastTab(event) {
        if (event.key === 'End') {
            this.activeTab = this.tabs[this.tabs.length - 1];
            document.querySelector(`#tab-${this.activeTab}`).focus();
            event.stopPropagation();
            event.preventDefault();
        }
    },
});
